import React from 'react'

// components
import Layout from 'src/layouts/BaseLayout'
import Hero from './sections/hero/_index'

// assets
import pageContext from './pageContext.json'
import { Wrapper } from './style'

const OrlandoCity = () => {
  const lang = 'pt'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default OrlandoCity
